import { generateCodeChallengeAndStoreVerifier, setAuthMethod, getAuthMethod, base64urlEncode, getLoginHint } from '../utils';
import { SUPPORT_LOGIN } from '../consts';

/** **
 * Create PKCE stuff and redirect to oidc login page
 */
const oidcAuthorize = async ({ redirectUrl, type, codeChallenge, isTestBot, getAzureAuthorizeURL, validateRedirectUrl }) => {
  setAuthMethod(type);
  // Store that we are using AZURE in localstorage, used by admin
  localStorage.setItem('AZURE', type);

  if (codeChallenge) {
    // We have a code_challenge from caller, meaning its an external app login.
    sessionStorage.setItem('ccauth-client-code-challenge', codeChallenge);
  }

  const loginHint = getLoginHint();
  // If testbot always show login prompt, if we have a login hint let azure choose prompt
  let prompt = '';
  if (isTestBot) {
    prompt = 'login';
  } else if (!loginHint) {
    prompt = 'select_account';
  }

  window.location.replace(
    getAzureAuthorizeURL({
      redirectUrl: validateRedirectUrl(redirectUrl),
      codeChallenge: base64urlEncode(await generateCodeChallengeAndStoreVerifier()),
      loginHint,
      prompt,
      state: `${getAuthMethod()}_${validateRedirectUrl(redirectUrl)}`,
    }),
  );
  return true;
};

const makeOIDCAuthorize = ({ isTestBot, getAzureAuthorizeURL, validateRedirectUrl }) => {
  const urlParams = new URLSearchParams(location.search);
  return (redirectUrl, type = SUPPORT_LOGIN) =>
    oidcAuthorize({
      redirectUrl,
      codeChallenge: urlParams.get('code_challenge'),
      type,
      isTestBot,
      getAzureAuthorizeURL,
      validateRedirectUrl,
    });
};
export default makeOIDCAuthorize;
