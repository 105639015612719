import { isLocalEnv, getAuthMethod } from '../utils';

const AZURE_LIVE_IDP = 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603/oauth2/v2.0';

const makeAzureUtils = ({ config }) => {
  console.debug('ccauth', 'makeAzureUtils', 'building azure utils', config);
  const getAzureIdp = () => (isLocalEnv() ? `http://localhost:${config.mockPort || 9191}/identityprovider/oidc` : AZURE_LIVE_IDP);
  const getAzureClientId = () => {
    console.debug('ccauth', 'makeAzureUtils', 'getAzureClientId', getAuthMethod(), config);
    return getAuthMethod() === 'support' ? config.azureClientId : config.azureClientIdUserOIDC;
  };
  const getAzureAuthorizeURL = ({ prompt, redirectUrl, codeChallenge, loginHint, state = '' }) =>
    `${getAzureIdp()}/authorize?client_id=${getAzureClientId()}&scope=openid&response_type=code${prompt ? `&prompt=${prompt}` : ''}${
      loginHint ? `&login_hint=${loginHint}` : ''
    }&redirect_uri=${redirectUrl}&nonce=guid1&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}`;

  return {
    getAzureIdp,
    getAzureClientId,
    getAzureAuthorizeURL,
  };
};

export default makeAzureUtils;
